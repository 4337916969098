<template>
  <div class="bg-theme h-100-vh">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="card" style="margin-top: 25%">
            <div class="card-body text-center">
              <img
                src="/logo.svg"
                alt="logo"
                style="height: 100px"
                class="mb-4"
              />
              <div v-if="!success">
                <h5>Set A New Password</h5>
                <p class="text-muted">
                  Lorem ipsum dolor sit amet consecteturt.
                </p>
                <alert type="danger" class="text-start" v-if="show_error">
                  <h5 class="mb-0">Failed</h5>
                  <p class="mb-0">
                    Lorem ipsum dolor sit amet consectetur adipisicing.
                  </p>
                </alert>
                <form @submit.prevent="submit" id="recover_account">
                  <div class="mb-4 text-start">
                    <div class="form-floating mb-3">
                      <input
                        type="password"
                        class="form-control"
                        id="password"
                        placeholder="Password"
                        name="password"
                        v-model="user.password"
                      />
                      <label for="password">Password</label>
                    </div>
                  </div>
                  <div class="mb-4 text-start">
                    <div class="form-floating mb-3">
                      <input
                        type="password"
                        class="form-control"
                        id="password-confirmation"
                        placeholder="Confirm Password"
                        v-model="user.password_confirmation"
                      />
                      <label for="password-confirmation"
                        >Confirm Password</label
                      >
                    </div>
                  </div>
                  <div>
                    <button class="btn btn-primary w-100">
                      Continue <i class="fa fa-arrow-right ms-2"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div v-if="success">
                <div class="mb-3">
                  <i
                    class="fad fa-shield-check text-success p-3 bg-light-blue round-50"
                    style="font-size: 30px"
                  ></i>
                </div>
                <h4>Password Changed!</h4>
                <p class="text-muted">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Corrupti, rerum? Quisquam nobis, earum provident.
                </p>
                <router-link
                  :to="{ name: 'login' }"
                  class="btn btn-primary w-100 btn-sm"
                  >Go to Login <i class="fa fa-arrow-right ms-2"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if(this.$store.getters.getAuthUser.name != undefined) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  
  data() {
    return {
      user: {},
      success: false,
      show_error: false,
    };
  },

  methods: {
    submit() {
      this.success = false;
      this.show_error = false;
      let data = this.user;
      data.form_id = "recover_account";
      data.token = this.$route.params.token;

      this.$axios
        .post("/api/v1/recover-account", data)
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.success = false;
          this.show_error = true;
        });
    },
  },
};
</script>
